import React from "react";
import buildUseLocalStorageWithPrefix from "./useLocalStorageWithPrefix";
export interface Plan {
  name: string;
  deductible: number;
  oopMax: number;
  annualCost: number;
  coinsurance: number;
  planSelection: string;
  isFamily: boolean;
}
export interface PlanSetters {
  setName: React.Dispatch<string>;
  setDeductible: React.Dispatch<string>;
  setOopMax: React.Dispatch<string>;
  setAnnualCost: React.Dispatch<string>;
  setCoinsurance: React.Dispatch<string>;
  setPlanSelection: React.Dispatch<string>;
  setIsFamily: React.Dispatch<string>
}
export default function usePlan(prefix:string): [Plan, PlanSetters] {
  const useLocalStorage = buildUseLocalStorageWithPrefix(prefix);
  const [ name, setName ] = useLocalStorage('name', `Plan ${prefix}`);
  const [ deductible, setDeductible ] = useLocalStorage('deductible', "5000");
  const [ oopMax, setOopMax ] = useLocalStorage('oopMax', '10000');
  const [ annualCost, setAnnualCost ] = useLocalStorage('annualCost', '1000');
  const [ coinsurance, setCoinsurance ] = useLocalStorage('coinsurance', '.75');
  const [ isFamily, setIsFamily ] = useLocalStorage('isFamily', 'false');
  const [ planSelection, setPlanSelection ] = useLocalStorage('planSelection', '');

  return [
    {
      name,
      deductible: Number(deductible),
      oopMax: Number(oopMax),
      annualCost: Number(annualCost),
      coinsurance: Number(coinsurance),
      planSelection,
      isFamily: isFamily === 'true',
    },
    {setName, setDeductible, setOopMax, setAnnualCost, setCoinsurance, setPlanSelection, setIsFamily},
  ];
}
import { Stack } from '@fluentui/react';
import React from 'react';
import CompareChart from './CompareChart';
import PlanForm from './PlanForm';
import usePlan from './usePlan';

function App() {
  const [planA, setPlanA] = usePlan('A');
  const [planB, setPlanB] = usePlan('B');
  return (
    <Stack horizontalAlign="center">
      <Stack.Item>
        <Stack wrap horizontal tokens={{childrenGap: '2rem'}}>
          <Stack.Item basis={200} grow>
            <PlanForm plan={planA} setters={setPlanA} />
          </Stack.Item>
          <Stack.Item basis={200} grow>
            <PlanForm plan={planB} setters={setPlanB} />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <div style={{width: '100vw', height: '50vw'}}>
          <CompareChart planA={planA} planB={planB} />
        </div>
      </Stack.Item>
    </Stack>
  );
}

export default App;

import {Checkbox, Dropdown, IDropdownOption, Position, SpinButton, Stack, TextField} from '@fluentui/react';
import React from 'react';
import dispatchOnChange from './dispatchOnChange';
import { Plan, PlanSetters } from './usePlan';
import plans from './plans.json';

export interface PlanFormProps {
  plan: Plan;
  setters: PlanSetters;
}

export default function PlanForm({ plan, setters }: PlanFormProps) {
  
  const {name, deductible, oopMax, annualCost, coinsurance, planSelection, isFamily} = plan;
  const {setName, setDeductible, setOopMax, setAnnualCost, setCoinsurance, setPlanSelection, setIsFamily} = setters;
  const planOptions: IDropdownOption[] = plans.map(plan => {
        return {
            key: plan.name,
            text: plan.name,
        }
    });

  const onDropdownSelect = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
      if (item) {
          const multiplier = isFamily ? 2 : 1;
          const selectedPlan = plans.filter(plan => plan.name === item.key)[0];
          setName(selectedPlan.name);
          setDeductible((selectedPlan.deductible * multiplier).toString());
          setOopMax((selectedPlan.oopMax * multiplier).toString());
          setCoinsurance(selectedPlan.coinsurance.toString());
          setPlanSelection(selectedPlan.name);
      }
  };

  const onCheckboxChange = (event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean):void => {
      if (event) {
          const multiplier = checked ? 2 : .5;
          setDeductible((plan.deductible * multiplier).toString());
          setOopMax((plan.oopMax * multiplier).toString());
          dispatchOnChange(setIsFamily)(undefined, checked ? 'true' : 'false')
      }
  }
  const clearDropdown = () => {
      if (planSelection !== '') {
        setPlanSelection('');
      }
  }

  return (
    <Stack horizontalAlign="center" horizontal wrap tokens={{ childrenGap: ".5rem"}}>
      <Stack.Item basis="25%">
        <TextField 
          label="Plan Name"
          value={name}
          onChange={dispatchOnChange(setName)}
          onKeyPress={clearDropdown}
        />
      </Stack.Item>
      <Stack.Item basis="25%">
        <Dropdown
          selectedKey={planSelection}
          label="Pre-filled plan"
          options={planOptions}
          placeholder={"Select a pre-filled plan..."}
          onChange={onDropdownSelect}
        />
      </Stack.Item>
      <Stack.Item align={"end"}>
        <Checkbox
          label={"Check for family"}
          defaultChecked={isFamily}
          onChange={onCheckboxChange}
        />
      </Stack.Item>
      <Stack.Item>
        <SpinButton
          label="Annual Cost"
          labelPosition={Position.top}
          value={annualCost.toString()}
          step={1000}
          precision={0}
          onChange={dispatchOnChange(setAnnualCost)}
        />
      </Stack.Item>
      <Stack.Item >
        <SpinButton
          label="Deductible"
          labelPosition={Position.top}
          value={deductible.toString()}
          min={0}
          step={1000}
          precision={0}
          onChange={dispatchOnChange(setDeductible)}
          onKeyPress={clearDropdown}
        />
      </Stack.Item>
      <Stack.Item>
        <SpinButton
          label="Out of Pocket Max"
          labelPosition={Position.top}
          value={oopMax.toString()}
          min={0}
          step={1000}
          precision={0}
          onChange={dispatchOnChange(setOopMax)}
          onKeyPress={clearDropdown}
        />
      </Stack.Item>
      <Stack.Item>
        <SpinButton
          label="Co-Insurance"
          labelPosition={Position.top}
          value={coinsurance.toString()}
          min={0}
          max={1}
          step={0.05}
          precision={2}
          onChange={dispatchOnChange(setCoinsurance)}
          onKeyPress={clearDropdown}
        />
      </Stack.Item>
    </Stack>
  )
}
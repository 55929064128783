import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Plan } from './usePlan';

const medicalBillAmounts = Array.from(Array(61).keys()).map((i) => i * 1000);

function calcCost(medicalBill: number, plan: Plan) {
  const paymentsTowardsDeductible = Math.min(plan.deductible, medicalBill);
  const paymentsAfterDeductible = Math.max(0, medicalBill - plan.deductible) * (1 - plan.coinsurance);
  return plan.annualCost + Math.min(plan.oopMax, paymentsTowardsDeductible + paymentsAfterDeductible);
}

export interface CompareChartProps {
  planA: Plan;
  planB: Plan;
}

export default function CompareChart({planA, planB}: CompareChartProps) {

  const data = medicalBillAmounts.map((medicalBill) => ({
    medicalBill,
    planA: calcCost(medicalBill, planA),
    planB: calcCost(medicalBill, planB), 
  }));
  return (
    <ResponsiveContainer width="95%" aspect={1.5}>
      <LineChart data={data} width={1000} height={500}>
        <XAxis height={70} scale="linear" label='Total Medical Bill' type='number' dataKey="medicalBill" />
        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
        <YAxis label={{value:'Cost to You', angle: -90, position: 'insideLeft'}} />
        <Line name={planA.name} type="monotone" dataKey="planA" stroke="#8884d8" />
        <Line name={planB.name} type="monotone" dataKey="planB" stroke="#82ca9d" />
        <Tooltip />
        <Legend verticalAlign="top" height={36}/>
      </LineChart>
    </ResponsiveContainer>
  );
}